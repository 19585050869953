// @flow
import Vue from 'vue';
import { UrlManager } from './config';
import { MessageBox } from 'element-ui'; // 引入
import {
  CONNECTION_ERROR,
  TIMEOUT_ERROR,
  WrappedErrorEvent,
  WrappedCloseEvent,
  CONNECTION_ERROR_CODE
} from './event';
// import { getCookie } from 'utils';
import {
  getCurrentTime,
  getWSTimoutMilliSecs,
  removeTailRecursive,
  getCookie
} from '~/utils/utils';
import { getContractWSUrl, getTokenData } from '~/utils/urlHelper';
import { inflate } from 'pako/lib/inflate';
import { get } from 'lodash';
import { LocalStorageKey } from '~/utils/LocalCache';
import { delLoginCookie } from '~/utils/utils';
import { invokeClearCookie } from '~/server/user/index2';
import { broadcastChannel } from '~/utils/broadcastChannel'
import sEvent from "../../utils/sensorsEvent";
import TokenCookie, {TokenKey} from "../../utils/TokenCookie";
import LocalCookie, {CookieKey} from "../../utils/localCookie";
// $flow-disable-line
// import globalConfig from 'configPath';
// $flow-disable-line
const globalConfig = require('configPath');
// $flow-disable-line

type ListenersMap = {
  error: Map<string, Function>,
  message: Map<string, Function>,
  open: Map<string, Function>,
  close: Map<string, Function>
};

// 支持的消息发送类型
type Message = string | ArrayBuffer | Blob;

// url的获取方式
// 直接字符串
// 返回url的方法，每次重连会调用
// 返回一个期待值为string的Promise，方便异步获取url时使用
type UrlProvider =
  | string
  | ((compress?: boolean) => string)
  | ((compress?: boolean) => Promise<string>);

// 可配置参数
type Options = {
  WebSocket?: any, // 可以自定义WebSocket的构造法，比如ws库，默认Websocket
  maxReconnectionDelay?: number, // 重连最大等待时间
  minReconnectionDelay?: number, // 重连最小等待时间
  reconnectionDelayGrowFactor?: number, // 重连等待时间增长速度，重连可以是不均匀
  connectionTimeout?: number, // 多长时间认为连接超时
  maxRetries?: number, // 最大重试次数
  maxEnqueuedMessages?: number, // 重连可用最大缓存量
  startClosed?: boolean, // Websocket关闭是否调用重连
  debug?: boolean, // 是否输出debug信息
  maxHeartbeat?: number, // 最大心跳数，超过该设置次数且没有收到服务器消息认为断开
  heartbeatInterval?: number, // 心跳间隔，毫秒数
  compress?: boolean, // 是否开启压缩
  token?: string // token
};
const SEPARATION = '#';
// 默认配置参数
const DEFAULT = {
  maxReconnectionDelay: 5000,
  minReconnectionDelay: 1000 + Math.random() * 4000,
  reconnectionDelayGrowFactor: 1.3,
  connectionTimeout: 5000,
  // maxRetries: Infinity,
  maxRetries: 50,
  maxEnqueuedMessages: Infinity,
  startClosed: false,
  // debug: globalConfig.env !== 'online', // 非线上环境开启
  debug: true, // 测试环境开启
  maxHeartbeat: 6, // 如果六次心跳之间没有
  heartbeatInterval: 5 * 1000, // 每5秒发送一次心跳
    sensorsReportInterval: 60 * 1000,  // 神策上报时间间隔，单位ms
  compress: !!process.env.WEBSOCKET_COMPRESSION
  // compress: globalConfig.socketCompress // 是否开启压缩
};

export default class WebsocketHero {
  version: string;
  _wsURL: string;
  _url: UrlProvider;
  _options: Options;
  _ws: WebSocket;
  _binaryType: string; // 与连接的binaryType同步
  _protocols: string | string[];
  _retryCount: number; // 重试次数
  _heartbeats: number; // 记录心跳次数，每当发送一次心跳+1，收到消息时清零

  _closeCalled: boolean; // 标明是手动close
  _shouldReconnect: boolean; // 标明不需要重连

  _connectLock: boolean; // 连接锁

  _connectTimeout: any; // 连接计时器
  _connectTimeoutSec: number; // 连接超时时间
  __connectDelay: number; // 重连延迟时间
  _beatTimeout: any; // 心跳定时器

  _messageQueue: Message[]; // 消息缓存队列
  _listeners: ListenersMap; // 监听器容器

  _channelMap: Map<string, Record>; // 保存订阅频道与监听器的映射关系

  productCode: string; // 合约名称

  urlManager: UrlManager; // 连接地址管理
  token: string; // Token
  _messageSent: boolean; // 是否发送过消息
  _isLogin: string;

  timeBoard: { [string]: number }; // 计时器
  _heartbeatTimes: [number];  // 心跳时间记录数组
  sensorsReport: TimeoutID | null;  // 心跳定时器

  _waitTimer: TimeoutID | null;  // 连接等待定时器

  constructor(url: UrlProvider, options: Options) {
    this._url = url;
    this._options = options;
    this._isLogin = window.$nuxt.$store.state.future.isLogin || TokenCookie.get(TokenKey.R_TOKEN)?.length > 0;
    // 初始化各种字段
    this._init();
    this._connect();
  }

  static instance: WebsocketHero | null;
  /**
   * 单例化
   * @return {WebsocketHero}
   */
  static async getInstance(): WebsocketHero {
    const url = await getContractWSUrl();
    const { hostname, search } = new URL(url);
    const { currentSearch = search } = new URL(window.location.href);
    const compress = search?.compress || currentSearch?.compress;
    if (search?.compress && !!compress !== this._options.compress) {
      this._options.compress = !!compress;
    }
    if(!this.instance){
      this.instance = new WebsocketHero(url, DEFAULT);
    } else {
      const prefix = hostname.split('.')[0]; // 取得目标前缀
      const { currentHostname = hostname } = new URL(this.instance._url);
      const prefixTarget = currentHostname.split('.')[0]; // 取得现有前缀
      if (prefix.toLowerCase() !== prefixTarget.toLowerCase()) {
        // this.instance.unsubscribeAll();
        // this.instance.close();
        this.instance.terminate();
        this.instance = new WebsocketHero(url, DEFAULT);
      }
    }
    return this.instance;
  }

  begin(productCode: string) {
    this.changeProduct(productCode);
    // 建立连接
    this._connect();
  }

  changeProduct(productCode: string) {
    this.productCode = productCode;
  }

  _init() {
    this.version = '2.0';
    // binaryType暂不使用
    this._binaryType = 'blob';
    // protocol暂不使用
    this._protocols = '';
    this._retryCount = -1;
    this._heartbeats = 0;
    // this._closeCalled = false;
    this._connectLock = false;
    this._connectTimeout = null;
    this._connectTimeoutSec = 5000;
    this._connectDelay = 0;
    this._messageQueue = [];
    this._shouldReconnect = true;
    this._listeners = {
      open: new Map(),
      close: new Map(),
      error: new Map(),
      message: new Map()
    };
    this._channelMap = new Map();
    this._messageSent = false;
    this.timeBoard = {}
    this._clearSensorsReportTimer();
  }

  // 以下内容是为了保证和原生WebSocket拥有相同的标准
  static get CONNECTING() {
    return 0;
  }

  static get OPEN() {
    return 1;
  }

  static get CLOSING() {
    return 2;
  }

  static get CLOSED() {
    return 3;
  }

  get CONNECTING() {
    return WebsocketHero.CONNECTING;
  }

  get OPEN() {
    return WebsocketHero.OPEN;
  }

  get CLOSING() {
    return WebsocketHero.CLOSING;
  }

  get CLOSED() {
    return WebsocketHero.CLOSED;
  }

  /**
   * 获取重试次数
   * @return {number}
   */
  get retryCount(): number {
    return Math.max(this._retryCount, 0);
  }

  /**
   * 返回连接状态，如果没有建立的连接，会根据配置返回一个基本装填
   * 如果startClosed属性为true 表示默认从closed状态开始，否则为正在连接
   * @return {number}
   */
  get readyState(): number {
    if (this._ws) {
      return this._ws.readyState;
    }
    return this._options?.startClosed ? WebsocketHero.CLOSED : WebsocketHero.CONNECTING;
  }

  /**
   * 代理onopen
   * @param event
   * @private
   */
  _handleOpen = (event: Event) => {
    // 正常流程：在connectionTimeout时间内连接上了
    clearTimeout(this._connectTimeout);
    this._heartbeats = 0;
    // this._retryCount = 0;
    // 发送缓存的消息
    this._debug('deal message queue:', JSON.stringify(this._messageQueue));
    this._messageQueue.forEach(message => this.send(message));
    this._messageQueue = [];

    //订阅缓存的channle
    if(this._channelMap.size > 0){
      for (const [action, {params, listener}] of this._channelMap) {
        this.subscribe(action, params, listener);
      }
    }

    // 开启心跳
    this._heartbeat();

    // 执行open钩子
    this._listeners.open.forEach(listener => listener(event));
  };

  _clearSensorsReportTimer() {
    this.sensorsReport && clearInterval(this.sensorsReport);
    if(this._heartbeatTimes && this._heartbeatTimes?.length > 0){
      sEvent('ws_response_time', this._url, {ext: this._heartbeatTimes.join(',')})
    }
    this._heartbeatTimes = [];
  }

  /**
   * 代理onclose
   * @param event
   * @param shouldReconnect
   * @private
   */
  _handleClose = (event: WrappedCloseEvent, shouldReconnect: boolean = true) => {
    // 正常流程：terminate-》close-》触发onclose到这里，closeCalled = true，_shouldReconnect = false
    this._clearTimeouts();
    this._clearSensorsReportTimer();
    if (event?.code === CONNECTION_ERROR_CODE.E1007) {
      this.msgBoxE1007();
    } else if (shouldReconnect) {
      // 异常流程1：超过规定时间没有建立连接，_shouldReconnect = true
      this.reconnect();
    }
    this._listeners.close.forEach(listener => listener(event));
  };

  /**
   * 触发1007错误时的提醒
   * @param err
   */
  msgBoxE1007() {
    let theme = LocalCookie.get(CookieKey.GLOBAL_THEME) || 'black';
    let cusClass = theme === 'white' ? 'big-icon-confirm single' : 'big-icon-confirm single black';
    let i18n = require('../../config/request.i18n.conf')();
    MessageBox.alert(`<div class="forbidden-content center">${i18n.wsErr1007}</div>`, '', {
      confirmButtonText: i18n.resetBtn,
      dangerouslyUseHTMLString: true,
      customClass: cusClass,
      type: 'warning',
      title: '',
      center: true,
      iconClass: 'iconfont icon-tishi',
      closeOnClickModal: false,
      closeOnPressEscape: false,
      showCancelButton: false
    }).then(() => {
      this.reconnect();
    });
  }

  /**
   * 处理onmessage
   * 预处理数据，分发数据
   * @param event
   * @private
   */
  _handleMessage = (event: MessageEvent) => {
    let rawMessage = event.data;
    this._retryCount = -1;
    this._messageSent = true;
    // 是否要处理压缩数据
    if (this._options.compress) {
      this._inflateMessage(rawMessage);
    } else {
      this._dispatchMessage(rawMessage);
    }
  };

  /**
   * 解压缩信息
   * @param rawMessage
   * @private
   */
  _inflateMessage(rawMessage: any) {
    if (rawMessage instanceof Blob) {
      let reader = new FileReader();
      let context = this;
      reader.addEventListener('loadend', function() {
        // $flow-disable-line
        let decodeMessage = inflate(reader.result, { to: 'string' });
        context._dispatchMessage(decodeMessage);
      });

      reader.onerror = function(error) {
        console.log('解压出现问题', error);
        // window.location.reload();
      };

      reader.readAsArrayBuffer(rawMessage);
    } else {
      this._dispatchMessage(rawMessage);
    }
  }

  /**
   * 分发消息
   * @param rawMessage
   * @private
   */
  _dispatchMessage(rawMessage: any) {
    let data = JSON.parse(String(rawMessage));
    // 判断服务端发送pong,清空心跳计数，记录心跳用时
    if(data.action == 'pong'){
      this._heartbeats = 0;
      // 记录心跳用时
      let time = this._timeEnd('ping');
      window.$nuxt.$store.commit('future/SET_WS_SPEED', time)
      this._heartbeatTimes.push(time);
    }
    let id = generateId(data.action, data);
    this._listeners.message.forEach(listener => listener(data, id));
  }

  /**
   * 处理异常，该方法不是直接对应于ErrorHandler
   * @param event
   * @private
   */
  _handleError(event: WrappedErrorEvent) {
    // 异常流程1：未在指定时间内建立连接，event.message = 'TIMEOUT'
    this._debug('error event', event.message);
    this._disconnect(undefined, event.message === 'TIMEOUT' ? 'timeout' : undefined);
    // 通知onerror订阅者
    this._listeners.error.forEach(listener => listener(event));
    // 重新建立连接
    // 注意：理论上_disconnect会触发_handleClose从而引起_connect
    // 如果_handleClose在引起重连时发生异常，此处仍能保证可以发起重连
    this.reconnect();
  }

  /**
   * 处理超时
   * @private
   */
  _handleTimeout() {
    // 异常流程1：未在指定时间内触发onopen，即未在规定时间内成功连接上
    this._debug('timeout event');
    this._handleError(new WrappedErrorEvent(null, TIMEOUT_ERROR, this));
  }

  /**
   * 初始化生命周期
   * @private
   */
  _initListeners() {
    if (this._ws) {
      // 正常流程：刚创建了原生websocket实例到_ws属性上
      this._ws.onopen = this._handleOpen;
      this._ws.onclose = (event: CloseEvent) => {
        let closeEvent = new WrappedCloseEvent(event);
        closeEvent.target = this;
        this._handleClose(closeEvent, closeEvent.reason !== 'terminate');
        // this._handleClose(closeEvent, true);
      };
      this._ws.onmessage = this._handleMessage;
      this._ws.onerror = (event: Event) => {
        this._handleError(new WrappedErrorEvent(event, CONNECTION_ERROR, this));
      };
    }
  }

  /**
   * 停止生命周期
   * @private
   */
  _removeListeners() {
    if (this._ws) {
      this._debug('removeListeners');
      // 将WebSocket视为只能处理DOM0级事件
      // 异常流程1：未在规定时间内建立连接，但是_initListeners已经执行，清掉这些绑定，防止稍后重连时触发的事件被错误的下发
      this._ws.onclose = noop;
      this._ws.onopen = noop;
      this._ws.onerror = noop;
      this._ws.onmessage = noop;
    }
  }

  /**
   * 连接延时
   * @return {Promise<R>}
   * @private
   */
  _wait(): Promise<void> {
    // 若已登入，不需要等待
    if (this._isLogin) {
      return Promise.resolve();
    } else {
      return new Promise(resolve => {
        // 这里固定重连延迟0s
        const delay =
          getWSTimoutMilliSecs(this._retryCount) +
          (this._retryCount <= 1 ? this._connectDelay : this._connectTimeoutSec);
        this._debug('waiting', delay);
        this._waitTimer = setTimeout(resolve, delay);
      });
    }
  }

  /**
   * 获取要用的URL
   * @param urlProvider
   * @return {Promise<WebsocketHero._getNextUrl.props|string>|Promise<string>|{then}|*}
   * @private
   */
  _getNextUrl(urlProvider: UrlProvider): Promise<string> {
    if (typeof urlProvider === 'string') {
      return Promise.resolve(urlProvider);
    }
    if (typeof urlProvider === 'function') {
      const url = urlProvider(this._options.compress);
      if (typeof url === 'string') {
        return Promise.resolve(url);
      }
      if (url.then) {
        return url;
      }
    }
    throw Error('Invalid URL');
  }

  _logout(){
    const that = window.$nuxt;
    that.$store.commit('CLEAR_USER');
    that.$store.commit('SET_TRADER_NEW_TIPS', false);
    that.$store.commit('SET_MIX_TRADER_NEW_TIPS', false);

    delLoginCookie();
    const response = new Promise((resolve, reject) => {
      invokeClearCookie()
        .then(res => {
          TokenCookie.remove(TokenKey.CC_TOKEN);
          TokenCookie.remove(TokenKey.R_TOKEN)
          broadcastChannel('removeItem','bt_uid');
          broadcastChannel('removeItem','lastLoginTime');
          resolve(res);
        })
        .catch(err => reject(err));
    });
    return response;
  }
  /**
   * 建立连接
   * @private
   */
  _connect() {
    // 正常流程：_connectLock = false, _shouldReconnect = true
    // 异常流程1：会在这里重新开始流程，_shouldReconnect = true
    if (this._connectLock || !this._shouldReconnect) {
      return;
    }
    this._connectLock = true;
    // 会在onopen是归零，所以该量可以作为重连次数标记
    this._retryCount++;
    this._debug('Connect retryCount:', this._retryCount);
    const {
      maxRetries = DEFAULT.maxRetries
    } = this._options;
    this._debug('Max maxRetries', maxRetries);
    // 正常流程：_retryCount = -1(特殊表示没有连接过)
    // 异常流程1：会导致这里并不为零
    // 若重连次数超过最大重连次数，或者已经登入且重连次数超过10次，自动登出
    if (this._retryCount >= maxRetries || (this._isLogin && this._retryCount >= 10)) {
      this._debug('Max retries reached', this._retryCount, '>=', maxRetries);
      this._logout().then(_ => {
        window.location.reload();
      });
      // TODO 尝试次数过多，自动登出
      return;
    }

    this._removeListeners();
    this._wait().then(() => {
      // this._protocols = generateRandomString(32);
      this._protocols = getTokenData(this._url);
      // console.log("this._protocols:",this._protocols)
      if (this._ws !== null && this._ws?.readyState === this.OPEN) this._ws.close();
      // console.log('this._protocols', this._protocols);
      // 刪除尾部的'='，base64尾部的＝是用来补齐3bytes的長度，但是websocket不需要
      this._protocols = removeTailRecursive(this._protocols, '=');
      // const isLogin = window.$nuxt.$store.getters.isLogin;
      this._ws =
        this._protocols.length > 0
          ? new WebSocket(this._url, this._protocols)
          : new WebSocket(this._url);
      console.log('this._ws', this._ws);

      // 重连时，有可能是目标域名被封或是被防攻击规则阻拦，此时使用新的url
      getContractWSUrl().then(url => {
        this._url = url;
        this._protocols = getTokenData(url);
        if (this._ws !== null && this._ws?.readyState === this.OPEN) {
          this._debug('_connect ws error :', this._ws);
          this._ws.close();
        }
        // 刪除尾部的'='，base64尾部的＝是用来补齐3bytes的長度，但是websocket不需要
        this._protocols = removeTailRecursive(this._protocols, '=');
        this._debug('Connecting to', url, 'with protocols', this._protocols);
        this._ws =
          this._protocols.length > 0 ? new WebSocket(url, this._protocols) : new WebSocket(url);

        this._connectLock = false;
        this._initListeners();
        // Call the function to check the WebSocket status
        this.checkWebSocketStatus(); // 确保close超时才能重新建立连接
      });
    });
  }

  /**
   * 断开连接
   * @param code
   * @param reason
   * @private
   */
  _disconnect(code: number = 1000, reason?: string) {
    // 异常流程1：未在指定时间内建立连接，reason = 'TIMEOUT'
    // 异常流程2：丢失6次心跳（这里会关闭心跳定时器）
    this._clearTimeouts();
    this._debug('_disconnect:', 'code:', code, 'reason:', reason);
    if (!this._ws) {
      return;
    }
    this._removeListeners();
    try {
      // TODO 处于connecting状态的连接是否可以close
      this._ws.close(code, reason);
      let closeEvent = new WrappedCloseEvent(null, code, reason);
      closeEvent.target = this;
      this._handleClose(closeEvent);
    } catch (error) {
      this._debug('_disconnect: error', error);
    }
  }

  /**
   * 开启心跳机制
   * @private
   */
  _heartbeat() {
    // 正常流程：onopen成功建立连接后才开启心跳机制
    if (this._beatTimeout) {
      clearTimeout(this._beatTimeout);
    }
    // 神策上报定时器
    this.sensorsReport && clearInterval(this.sensorsReport);
    let beat = () => {
      let {
        maxHeartbeat = DEFAULT.maxHeartbeat,
        heartbeatInterval = DEFAULT.heartbeatInterval
      } = this._options;
      // 不再使用系统时间作为判断条件
      if (this._heartbeats > maxHeartbeat) {
        // 这里就已经认为是断开了，需要进行重复连
        // 异常流程2：丢失六次心跳（_disconnect时会关闭心跳定时器）
        this._debug('_heartbeat:', this._heartbeats, '> maxHeartbeat', maxHeartbeat);
        this.reconnect(4999, 'heartbeat');

        // ws断开重连时停止神策上报，如果当前有记录值，则立即上报一次
        this._clearSensorsReportTimer();
        return;
      }
      if (this._ws.readyState === WebSocket.OPEN) {
        // 当心跳计数为0是记录ping开始时间，避免服务端不发送pong重置计时
        if(this._heartbeats == 0){
          this._time('ping');
        }
        this.send(JSON.stringify({ action: 'ping' }));
      }else {
        // ws断开重连时停止神策上报，如果当前有记录值，则立即上报一次
        this._clearSensorsReportTimer();
      }
      // 该变量会在onmessage时，收到pong消息清零，所以可以表示多少次没有收到pong
      this._heartbeats++;
      // 30s发送一次心跳，用setTimeout的嵌套取代setInterval
      this._beatTimeout = setTimeout(() => {
        beat();
      }, heartbeatInterval);
    };
    beat();
    // 开始心跳时开启神策上报定时器，根据心跳间隔和心跳时间记录最大长度确定上报时间间隔
    this.sensorsReport = setInterval(() => {
      sEvent('ws_response_time', this._url, {ext: this._heartbeatTimes.join(',')})
      this._heartbeatTimes = [];
    } , DEFAULT.sensorsReportInterval)
  }

  /**
   * 手动关闭，参数会被传递到onclose钩子中
   * @param code 关闭给的状态码
   * @param reason 关闭给的原因
   */
  close(code: number = 1000, reason?: string) {
    // 正常流程由terminate调用，请求正常关闭，reason为特殊标识
    this._closeCalled = true;
    this._shouldReconnect = false;
    this._clearTimeouts();
    // 关闭时清除定时器，避免等待时关闭没有关掉
    if(this._waitTimer) clearTimeout(this._waitTimer);
    if (!this._ws) {
      this._debug('Close enqueued: no ws instance');
      return;
    }
    if (this._ws.readyState === this.CLOSED) {
      this._debug('Already closed');
      return;
    }
    // 这里会触发onclose
    this._ws.close(code, reason);
    this._debug('Triggered closed already');
  }

  /**
   * 手动关闭连接并清掉相关内容
   */
  terminate() {
    // 正常流程：组件的beforeDestroy周期调用该方法释放连接和资源
    // 算作正常关闭
    this.close(1000, 'terminate');
    // 执行清理计划
    this._debug('Execute termination');
    // 释放实例
    WebsocketHero.instance = null;
    // 正常流程：到此结束，一切回到最初的起点
    this._init();
  }

  /**
   * 重连
   * @param code
   * @param reason
   */
  reconnect(code?: number, reason?: string) {
    if(this._closeCalled) return;
    this._shouldReconnect = true;
    this._closeCalled = false;
    if (!this._ws || this._ws.readyState === this.CLOSED) {
      this._debug('ws is null or closed, reconnect');
      this._connect();
    } else {
      this._debug('ws is not null or closed, reconnect');
      // 异常流程2：丢失6次心跳
      this._disconnect(code, reason);
      // 注：这里的_connect也是防止_handleClose产生的异常
      this._connect();
    }
  }

  /**
   * 打印信息
   * @param args
   * @private
   */
  _debug(...args: any[]) {
    if (this._options.debug) {
      console.log.apply(console, [`hero says> ${getCurrentTime()}`, ...args]);
    }
  }

  /**
   * 清空定时器
   * @private
   */
  _clearTimeouts() {
    clearTimeout(this._connectTimeout);
    clearTimeout(this._beatTimeout);
  }

  /**
   * 增加事件监听
   * @param type
   * @param listener
   * @return {*}
   */
  registerEventListener(type: $Keys<ListenersMap>, listener: Function): string {
    let randomKey = type + String(Date.now());
    this._listeners[type].set(randomKey, listener);
    if (type === 'open' && this.readyState === WebsocketHero?.OPEN) {
      listener();
    }
    return randomKey;
  }

  /**
   * 取消事件监听
   * @param type
   * @param listenerIndex
   */
  removeEventListener(type: $Keys<ListenersMap>, listenerIndex: string) {
    this._listeners[type].delete(listenerIndex);
  }

  /**
   * 发送消息
   * @param data
   */
  send(data: Message) {
    if (this._ws && this._ws.readyState === this.OPEN) {
      this._ws.send(data);
      if(JSON.stringify(data)?.action !== 'ping'){
        // 神策上报订阅信息
        sEvent('ws_swap_content', this._url, {ext: JSON.stringify(data)})
      }
    } else {
      this._debug('Enqueue', data);
      this._messageQueue.push(data);
    }
  }

  subscribe(action: string, params: any = {}, listener: (msg: any, id: any) => mixed): string {
    // 兼容参数
    if (params instanceof Function) {
      listener = params;
      params = {};
    }
    let msg = params;

    if (params.token?.length > 0) {
      msg.token = params.token;
    }

    let id = generateId(action, msg);
    if (this._listeners.message.get(id)) {
      this._listeners.message.delete(id);
    }

    this._listeners.message.set(id, (receivedData, receivedMessageId) => {
      // console.log('0000 receivedData this.productCode', this.productCode);
      // console.log('0000 receivedData receivedData.productCode', receivedData.productCode);

      if (
        this.productCode &&
        this.productCode?.length > 0 &&
        receivedData.productCode &&
        receivedData.productCode !== this.productCode
      ) {
        // console.log('0000 receivedData.productCode', receivedData.productCode)
        // console.log('0000 receivedData.this.productCode', this.productCode)
        return;
      }

      if (receivedMessageId === id) {
        listener(receivedData, receivedMessageId);
      }
    });

    let index = 'message' + String(Date.now());
    const sendContent = JSON.stringify({
      action,
      ...msg,
      msgId: index
    });
    this._debug('subscribe', sendContent);
    this.send(sendContent);
    this._channelMap.set(action, {action, params, listener})
    return id + SEPARATION + index;
  }

  /**
   * 取消订阅
   * @return {boolean}
   * @param listenerId 监听器id，由id#index构成
   */
  unsubscribe(listenerId: string) {
    // let [_, index] = listenerId.split(SEPARATION);
    // this.removeEventListener('message', index);
    this._listeners.message = new Map();
    this._channelMap = new Map();
  }

  unsubscribeAll(...actions: string[]): void {
    if (actions.length <= 0) {
      return;
    }

    for (let action of actions) {
      const sendContent = JSON.stringify({
        action: 'unsub_' + action.split('|')[0]
      });
      this._debug('UnsubscribeAll', sendContent);
      this.send(sendContent);
      this._channelMap.delete(action);
    }
  }

  selfDestruct() {
    // 正常流程：组件的beforeDestroy周期调用该方法释放连接和资源
    this.terminate();
    this._debug('selfDestruct');
    this.urlManager && this.urlManager.stop();
  }

  // Check the WebSocket connection status and messageSent flag
  checkWebSocketStatus() {
    if (this._ws.readyState === this.OPEN) {
      if (this._messageSent) {
        this._debug('WebSocket connected and message sent');
      } else {
        this._debug('WebSocket connected but no message sent');
      }
    } else if (this._ws.readyState === this.CONNECTING) {
      // 异常流程1：未在指定时间内触发onopen，即未在规定时间内成功连接上
      this._connectTimeout = setTimeout(() => this.close(), this._connectTimeoutSec);
    } else {
      this._debug('this._ws.readyState', this._ws.readyState);
      this._debug('WebSocket connection not yet established');
    }
  }

  /**
   * 计时
   * @param label
   * @private
   */
  _time(label) {
    this.timeBoard[label] = Date.now();
  }

  /**
   * 计时结束
   * @param label
   * @private
   */
  _timeEnd(label) {
    if (this.timeBoard[label]) {
      return Date.now() - this.timeBoard[label];
    }

    return 0;
  }
}

/**
 * 空函数
 */
function noop() {}

// 构成信息ID的键
const ID_PART_KEYS = ['step', 'productCode'];

/**
 * 由action和params组合生成用于分发的唯一标识
 * @param action
 * @param params
 * @return {string} action|key1:value1|key2:value2
 */
export function generateId(action: string, params: any = {}): string {
  // 如果订阅的是跟单数据 不对params 进行签名
  if (action === 'currentTraceOrders') {
    return action;
  }
  let result = action;
  Object.keys(params)
    .sort((a, b) => {
      return a < b ? -1 : a === b ? 0 : 1;
    })
    .forEach(key => {
      if (ID_PART_KEYS.indexOf(key) > -1) {
        result += `|${key}:${params[key]}`;
      }
    });
  return result;
}
